import React, { Component } from 'react'
import ASKey from "../../build/contracts/ASKey.json"
import getWeb3 from "../utils/getWeb3"
import truffleContract from "truffle-contract"
import Layout from "../components/layout"

import styled from 'styled-components'

const Container = styled.div`
  bottom: 0;
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid white;
  padding: 1em;
  position: relative;
`

const AccountHeader = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 5px;
  padding: 2px;
  border: 1px dashed white;

  font-size: 0.8rem;

  h4 {
    margin: 0;
  }
`

const MintForm = styled.form`

  textarea {
    display: block;
    margin: 5px 0 15px 0;
    background: #222;
    color: white;
    border-color: #888;
  }

  label {
    font-size: 1.1rem;
    font-weight: bold;
  }

  input[type=submit] {
    background: #222;
    color: white;
    font-size: 0.8em;
    border-radius: 3px;
    cursor: pointer;
  }
`

class Admin extends Component {
  state = {
    storageValue: 0,
    web3: null,
    accounts: null,
    contract: null,
    roleWhitelisted: false,
    mintForm: {
      addresses: ""
    }
  };

  async componentDidMount() {
    try {
      // Get network provider and web3 instance.
      const web3 = await getWeb3();

      // Use web3 to get the user's accounts.
      const accounts = await web3.eth.getAccounts();

      // Get the contract instance.
      const Contract = truffleContract(ASKey);
      Contract.setProvider(web3.currentProvider);
      const instance = await Contract.deployed();

      // Set web3, accounts, and contract to the state, and then proceed with an
      // example of interacting with the contract's methods.
      this.setState({ web3, accounts, contract: instance }, this.runExample);
    } catch (error) {
      // Catch any errors for any of the above operations.
      alert(
        `Failed to load web3, accounts, or contract. Check console for details.`
      );
      console.log(error);
    }
  };



  runExample = async () => {
    const { accounts, contract } = this.state;

    // Check Whitelisted Status
    const rw = await contract.whitelist.call(accounts[0], {from: accounts[0] });

    // Stores a given value, 5 by default.
    // await contract.set(5, { from: accounts[0] });

    // Get the value from the contract to prove it worked.
    // const response = await contract.get();

    // Update state with the result.
    // this.setState({ storageValue: response.toNumber() });
    this.setState({ roleWhitelisted: rw });
  };

  handleInput = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      mintForm: {
        ...this.state.mintForm,
        [name]: value
      }
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { accounts, contract } = this.state;

    contract.batchMint(
      this.state.mintForm.addresses.trim().split("\n"),
      {from: accounts[0]}
    ).catch(function(error) {
      alert("Error executing contract: " + error)
    })
  }

  render() {
    if (!this.state.web3) {
      return <div>Loading Web3, accounts, and contract...</div>;
    } else if (!this.state.roleWhitelisted) {
      return <div/>
    } else {
      return (
      <Layout>
        <Container>
          <h1>AS Key — Admin</h1>
          <AccountHeader>
            <h4>Your Address</h4>
            <div>{this.state.accounts[0]}</div>
          </AccountHeader>
          <p>This form is for the minting of new AS Keys. Please add a list of ethereum addresses (one address per line).</p>

          <MintForm onSubmit={this.handleSubmit}>
            <label>Addresses</label>
            <textarea type="text" cols="50" rows="30"
              name="addresses"
              value={this.state.mintForm.addresses}
              onChange={this.handleInput}/>

            <input type="submit" value="submit"/>
          </MintForm>
        </Container>
      </Layout>
      )
    }
  }
}

export default Admin
